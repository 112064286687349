import { useMutation } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import { useLocation } from "@reach/router";
import axios from "axios";

const addTranslatorRegister = (postData) => {
  // return http().post(endpoints.auth.translatorRegister, postData);
  return axios.post(
    "https://vendor.tomedes.com/api/vendor-register",
    postData
  );
};

export function useTranslatorRegister() {
  return useMutation(addTranslatorRegister, {
    onError: (errorData) => {
      const errorMessage = errorData?.error?.message || errorData?.message;
      // alert(errorMessage);
      console.log(errorMessage, "erormessgae");
    },
  });
}
